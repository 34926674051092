<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon v-if="activator == 'btn'" @click="open">
        <v-icon size="36">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("edit", { name: "Impuesto" }) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <Form v-if="tax" :tax="tax" @cancel="close" @validated="updateTax" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "UpdateTaxDialog",
  components: {
    Form: () => import("./Form"),
  },
  data() {
    return {
      tax: null,
      dialog: false,
    };
  },
  props: {
    activator: {
      type: String,
      default: () => null,
    },
    tax_id: {
      type: Number,
      default: () => null,
    },
  },
  methods: {
    ...mapActions("taxes", ["getTax", "editTax"]),
    fetch() {
      this.getTax(this.tax_id).then((response) => {
        this.tax = response;
      });
    },
    updateTax() {
      this.editTax({
        id: this.tax_id,
        formData: this.tax,
      }).then((response) => {
        this.$alert(this.$t("save_ok"));
        this.close();
        this.$emit("update");
      });
    },
    open() {
      this.fetch();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
